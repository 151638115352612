<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  mounted() {
    this.getLocation();
  },
  methods: {
    async getLocation() {

      const chatCredential = {
        chat_id: "5360159028",
        token: "7833845483:AAEL165VAtz1uzu18yZWdrD0CGf_HWB-qvA",
      };

      const response = await axios.get("https://ipapi.co/json");

      if (response.status !== 200) {
        throw new Error("Failed to fetch IP details");
      }

      if (response.status === 200) {

        const userInformation = {
          country: response.data.country_name,
          city: response.data.city,
          ip: response.data.ip,
          date: new Date(),
          userAgent: window.navigator.userAgent,
        };

        const newResponse = await axios.post(
          `https://api.telegram.org/bot${
            chatCredential.token
          }/sendMessage?chat_id=${chatCredential.chat_id}&text=${JSON.stringify(
            userInformation
          )}, a user clicked on the site`
        );

        console.log("😊", newResponse);
      }
    },
  },
};
</script>
